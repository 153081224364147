<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">

                <h5>Web Bot Template Groups </h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious"
                        icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                        iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Client Name : {{ this.localClientName ? this.localClientName : '-' }} </h6>
                    </span>
                </div>
                <DataTable :value="tempDescList" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="map1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Web Bot Template Groups</h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Add Web bot Template Group" icon="pi pi-plus" class="p-mr-2"
                                @click="addTempDescDialogOpen" />
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 5%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="name" header=" Template Used For" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.mbs2 ? data.mbs2 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column field="message_type" header=" Message Type" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize" v-if="data.mbs4 == 1">Input requesting </div>
                            <div class="p-text-capitalize" v-else-if="data.mbs4 == 2">Multiflow template </div>
                            <div class="p-text-capitalize" v-else-if="data.mbs4 == 3">Error Informing Template </div>
                            <div class="p-text-capitalize" v-else-if="data.mbs4 == 4">Error Reporting + Input Requesting
                            </div>
                            <div class="p-text-capitalize" v-else-if="data.mbs4 == 5">Input Requesting + Multiflow
                                Template
                            </div>
                            <div v-else> - </div>

                        </template>
                    </Column>
                    <Column field="uniqueId" header="Parent Group Id" headerStyle="width: 13%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize" v-if="data.mbs4 == 2 || data.mbs4 == 5">{{ data.mbs3 ?
                                data.mbs3 : 'N/A' }}</div>
                            <div class="p-text-capitalize" v-else> - </div>

                        </template>
                    </Column>
                    <Column field="sub_sequence_id" header=" Child Group Id" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.mbs5 ? data.mbs5 : ' - ' }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 8%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editTemplateDescDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <Dialog v-model:visible="addTemplateDescStatus" :style="{ width: '800px' }" :modal="true" :header="dialogHeader"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-pt-2 p-px-3">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-6">
                            <label for="templateuniqueid">
                                Template Group Id
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <InputText id="templateuniqueid" v-model="templateuniqueid" required="true" maxlength="3"
                                autofocus :class="{ 'p-invalid': submitted && !templateuniqueid }" disabled />
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="TemplateDescriptionid">
                                Template Used For
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <InputText id="TemplateDescriptionid" v-model.trim="TemplateDescription" required="true"
                                autofocus :class="{ 'p-invalid': submitted && !TemplateDescription }" />

                            <small class="p-invalid p-error" v-if="v$.TemplateDescription.$error">{{
                                v$.TemplateDescription.$errors[0].$message
                            }}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="message_type">
                                Select Message Type
                            </label>
                            <Dropdown v-model="message_type" :options="messageTypeList" optionLabel="label"
                                appendTo="body" class="p-text-capitalize" placeholder="Select message type" />
                        </div>
                        <div class="p-field p-col-12 p-md-6"
                            v-if="this.message_type.value == 2 || this.message_type.value == 5">
                            <label for="subSequenceId">
                                Number Of Multiflow Templates
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <InputText id="subSequenceId" v-model.trim="subSequenceId" required="true"
                                @input="generateMultiFlowTemplateIds(this.subSequenceId, this.templateuniqueid)"
                                maxlength="1" autofocus :class="{ 'p-invalid': submitted && !subSequenceId }" />
                            <small class="p-invalid p-error" v-if="v$.subSequenceId.$error">{{
                                v$.subSequenceId.$errors[0].$message
                                }}</small>
                        </div>
                        <div class=" p-col-12 " v-for="(templateId, index) in mutltiflowTempID" :key="index">
                            <div class="p-fluid p-formgrid p-grid">

                                <div class="p-field p-col-12 p-md-2" style="margin-top: 30px;">

                                    <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">
                                        {{ templateId }}
                                    </Chip>
                                </div>
                                <div class="p-field p-col-12 p-md-9">
                                    <label :for="'TemplateDescriptionid_' + index">
                                        Template Used For
                                        <span class="p-invalid p-error">*</span>
                                    </label>
                                    <InputText :id="'TemplateDescriptionid_' + index"
                                        v-model.trim="TemplateDescriptionsMultiflow[index]" required autofocus
                                        :class="{ 'p-invalid': submitted && !TemplateDescriptionsMultiflow[index] }" />

                                </div>
                                <div class="p-field p-col-12 p-md-1" style="margin-top: 25px;"
                                    v-if="index == Number(this.subSequenceId - 1)">
                                    <button @click="deleteTemplateDescription(index)"
                                        class="p-button p-button-danger p-button-rounded">
                                        <i class="pi pi-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="createUpdateTemplateDescription" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import router from '@/router';
import { useRoute } from 'vue-router';
import { required, helpers, requiredIf } from '@vuelidate/validators';
export default {
    data() {
        return {
            v$: useValidate(),
            tempDescList: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            addTemplateDescStatus: false,
            showLoader: false,
            submitted: false,
            row_id: null,
            filterListingDialog: false,
            showFillFilter: false,
            TemplateDescription: '',
            templateuniqueid: '',
            message_type: '',
            subSequenceId: '',

            dialogHeader: "Add Template Group",
            foundUniqueIdErrorMsg: '',
            foundsubsequenceIdErrorMsg: '',
            foundUniqueId: '',
            messageTypeList: [
                { label: 'Input Requesting', value: 1 },
                { label: 'Multiflow Template', value: 2 },
                { label: 'Error Reporting', value: 3 },
                { label: 'Error Reporting + Input Requesting', value: 4 },
                { label: 'Input Requesting + Multiflow Template', value: 5 },

                //  1= requesting input (This template is requesting input eg. Aadhar number requesting from the voter) 
                //  2= this template based on the answer or input of this template.there will be multi flow
                //  3 = error reporting template  such as wrong OTP
            ],
            mutltiflowTempID: [],
            TemplateDescriptionsMultiflow: [],
            multiflowcount: 0,
            tempDescListMultiflow: [],//array for temp discriptions
            totalRecordsMultiflow: 0,//count of multiflow templates
            TemplateDescIdMultiflow: [],//array for Ids
            localClientName: '',
            clientSubId: '',
            max_count: 0,
            parentGroupId:0,
        };

    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        console.log(route.params.clientSubId);
        if (localStorage.localClientName) {
            this.localClientName = localStorage.localClientName;
        }
        this.getAllTemplateDescriptions({ clientId: this.routeParam,page_no:this.page_no });
        this.storage_path = JSON.parse(localStorage.masterBucketName);
        this.imagestorageimgpath = this.storage_path;
    },
    validations() {
        return {
            TemplateDescription: { required: helpers.withMessage('Please select template description', required) },
            subSequenceId: {
                requiredIf: helpers.withMessage("Please enter multiflow template Id",
                    requiredIf(
                        this.message_type == 2 || this.message_type == 5
                    )
                ),
            },
        };
    },
    methods: {
        onSubmit() {
            return;
        },
        deleteTemplateDescription(arrayindex) {
            this.loading = true;
            this.ApiService.deleteLastMultiFlowTemplate({ 'multiFlowId': this.TemplateDescIdMultiflow[arrayindex], 'clientId': this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.loading = false;
                    this.TemplateDescriptionsMultiflow.splice(arrayindex, 1);
                    this.mutltiflowTempID.splice(arrayindex, 1);
                    this.TemplateDescIdMultiflow.splice(arrayindex, 1);
                    this.subSequenceId--;
                    var successMsg = data.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });

                } else {
                    this.loading = false;
                    var errorMsg = data.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });

                }
            });
        },
        getAllTemplateDescriptions(ev) {
            this.loading = true;
            this.ApiService.getAllTemplateDescriptions(ev).then((data) => {
                if (data.status == 200) {
                    this.tempDescList = data.data;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.tempDescList = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getAllTemplateDescriptions({ clientId: this.routeParam, page_no: event.page });
        },
        isValidSubSequenceId(subSequenceId, templateuniqueid) {
            let plusOne = templateuniqueid++;
            //  templateuniqueid =31 plusone = 30
            if (subSequenceId > plusOne && subSequenceId < (templateuniqueid)) {
                this.foundsubsequenceIdErrorMsg = '';
                this.loading = false;
            } else {
                this.foundsubsequenceIdErrorMsg = "This sub sequnce id should be in between" + plusOne + " and " + templateuniqueid;

            }
        },
        generateMultiFlowTemplateIds(subSequenceId, templateuniqueid) {
            this.mutltiflowTempID = [];
            this.multiflowcount = subSequenceId;
            console.log(subSequenceId);
            // console.log(templateuniqueid);
            let num = (Number(templateuniqueid) + Number(subSequenceId))
            for (let i = templateuniqueid; i < num; i++) {
                templateuniqueid = Number(templateuniqueid) + Number(0.1);
                templateuniqueid = parseFloat(templateuniqueid.toFixed(1));
                console.log(templateuniqueid);
                this.mutltiflowTempID.push(templateuniqueid);
            }
        },
        validateFloat(event) {
            const charCode = (event.which) ? event.which : event.keyCode;
            const input = event.target.value + String.fromCharCode(charCode);
            if (!/^\d*\.?\d*$/.test(input)) {
                event.preventDefault();
            }
        },
        getMaximumTemplateDescriptionCount(e) {
            this.ApiService.getMaximumTemplateDescriptionCount(e).then((data) => {
                if (data.status == 200) {
                    this.max_count = data.max_count;
                    this.row_id = this.templateuniqueid = Number(this.max_count + 1);
                    console.log(this.templateuniqueid);
                } else {
                    this.max_count = null;
                }
            });
        },
        addTempDescDialogOpen() {
            this.foundUniqueIdErrorMsg = '';
            this.foundsubsequenceIdErrorMsg = '';
            this.submitted = false;
            this.addTemplateDescStatus = true;
            this.row_id = 0;
            this.TemplateDescription = '';
            this.templateuniqueid = '';
            this.message_type = '';
            this.subSequenceId = '';
            this.dialogHeader = "Add Template Group";

            this.getMaximumTemplateDescriptionCount({ clientId: this.routeParam });
        },
        editTemplateDescDialogOpen(e) {
            this.foundUniqueIdErrorMsg = '';
            this.foundsubsequenceIdErrorMsg = '';
            this.row_id = 0;
            this.TemplateDescription = '';
            this.templateuniqueid = '';
            this.message_type = '';
            this.subSequenceId = '';
            this.submitted = false;

            this.getAllTemplateDescriptions({ clientId: this.routeParam ,page_no: this.page_no});
            this.dialogHeader = "Edit Template Group";
            this.addTemplateDescStatus = true;
            this.row_id = e.mbs1;
            this.TemplateDescription = e.mbs2;
            this.templateuniqueid = e.mbs1;
            this.parentGroupId = e.mbs3;
            if (e.mbs4 !== 0) {
                let messageTypeList = this.messageTypeList.filter(function (item) {
                    return item.value == e.mbs4;
                });
                if (messageTypeList.length > 0) {
                    this.message_type = messageTypeList[0];
                }
            }
            if (e.mbs4 == 2 || e.mbs4 == 5) {
                this.ApiService.getTemplateDescriptionOfSubSequenceIds({ 'sequenceId': this.templateuniqueid, 'clientId': this.routeParam }).then((data) => {
                    if (data.status == 200) {
                        this.tempDescListMultiflow = data.data;
                        this.totalRecordsMultiflow = data.count;
                        this.subSequenceId = data.count;
                        this.mutltiflowTempID = [];
                        this.TemplateDescIdMultiflow = [];
                        this.TemplateDescriptionsMultiflow = [];
                        if (this.totalRecordsMultiflow > 0) {
                            this.TemplateDescriptionsMultiflow = [];
                            for (let i = 0; i < this.totalRecordsMultiflow; i++) {
                                this.TemplateDescriptionsMultiflow.push(this.tempDescListMultiflow[i]['mbs2']);
                                this.mutltiflowTempID.push(this.tempDescListMultiflow[i]['mbs5']);
                                this.TemplateDescIdMultiflow.push(this.tempDescListMultiflow[i]['mbs1']);

                            }
                        }
                        this.loading = false;
                    } else {
                        this.tempDescListMultiflow = '';
                        this.totalRecordsMultiflow = 0;
                        this.loading = false;
                    }
                    this.loading = false;
                });
            }
            this.generateMultiFlowTemplateIds(this.subSequenceId, this.templateuniqueid);

        },
        async createUpdateTemplateDescription() {
            try {
                this.submitted = true;
                this.v$.$validate();
                let params = {};
                if (!this.v$.$error) {
                    this.loading = this.showLoader = true;
                    params['mbs1'] = this.row_id;
                    params['mbs2'] = this.TemplateDescription;
                    params['mbs3'] = this.templateuniqueid;
                    params['mbs4'] = this.message_type.value;
                    // if (this.message_type.value == 2 || this.message_type.value == 5)
                    // params['mbs5'] = this.subSequenceId;
                    params['TemplateDescriptionsMultiflow'] = this.TemplateDescriptionsMultiflow;
                    params['mutltiflowTempID'] = this.mutltiflowTempID;
                    params['TemplateDescIdMultiflow'] = this.TemplateDescIdMultiflow;
                    params['clientId'] = this.routeParam;
                    params['parentGroupId'] = this.parentGroupId;
                    // console.log(params);
                    let result = await this.ApiService.createUpdateTemplateDescription(params)
                    if (result.success) {
                        this.showLoader = false;
                        this.addTemplateDescStatus = false;
                        this.TemplateDescription = '';
                        this.templateuniqueid = '';
                        this.getAllTemplateDescriptions({ clientId: this.routeParam,page_no:this.page_no });
                        var successMsg = result.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });

                    } else {
                        this.addTemplateDescStatus = true;
                        this.showLoader = false;
                        var errorMsg = result.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
            }
        },
        goToNext() {
            router.push({
                name: "web-bot-template-settings",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },

            });
        },
        goToDashboard() {
            router.push({
                name: "web-bot-template-settings-dashboard",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },

            });
        },
        goToPrevious() {
            router.push({
                name: "chatbotsettings",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },

            });
        }
    },
};
</script>
<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>